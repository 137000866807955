import classnames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  FormFeedback,
  FormGroup,
  Label,
  Row
} from 'reactstrap'

import FullPageLoader from '../components/FullPageLoader'
import { getFirebaseHandler } from '../utils/firebaseHandler'
import profile from './../public/assets/images/profile-img.png'

const Login = (props) => {
  const router = useRouter()

  const { t, i18n } = useTranslation()

  const [invalid, setInvalid] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChange'
  })

  const handleValidSubmit = async (fieldValues) => {
    try {
      const { email, password } = fieldValues
      getFirebaseHandler()
        .loginUser(email, password)
        .then(() => {
          router.push('/displays').then()
        })
    } catch (e) {
      setInvalid(true)
    }
  }

  const showErrors = (id) => {
    const errorType = errors[id]?.type
    if (!errorType) return null

    switch (errorType) {
      case 'required':
        return <span>{t('This is required')}</span>
      case 'pattern':
        return <span>{t('Invalid email address')}</span>
    }
  }

  return (
    <>
      <div className="account-pages">
        <Container className="">
          <Row
            className="justify-content-center align-items-center"
            style={{ height: 'calc(100vh - 60px)', minHeight: '660px' }}
          >
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <div className="col col-xs-7 d-flex align-items-center">
                      <div className="text-primary ps-2 ps-sm-4 py-4 py-sm-0">
                        <h4 className="text-primary">{t('Welcome Back !')}</h4>
                        <p className="mb-0">
                          {t('Sign in to continue to Promolayer.')}
                        </p>
                      </div>
                    </div>
                    <div className="col col-5 align-self-end">
                      <Image
                        src={profile}
                        alt=""
                        className="img-fluid align-right"
                      />
                    </div>
                  </Row>
                </div>
                <CardBody className="pt-3">
                  <div className="pt-3">
                    <div className="row">
                      <div className="col flex-grow-1">
                        <a
                          className="btn btn-light col-12"
                          href={
                            i18n.language === 'ja'
                              ? 'https://promolayer.crisp.help/ja/article/shopify-zlqpzp/'
                              : 'https://promolayer.crisp.help/en/article/shopify-user-login-guide-efcsqk/'
                          }
                          target="_blank"
                        >
                          {' '}
                          {t('Shopify users')}{' '}
                          <i className="mdi mdi-open-in-new" />
                        </a>
                      </div>
                      <div className="col">
                        <a
                          className="btn btn-light col-12"
                          href={
                            i18n.language === 'ja'
                              ? 'https://promolayer.crisp.help/ja/article/wix-user-login-guide-1qkpvqc/'
                              : 'https://promolayer.crisp.help/en/article/wix-user-login-guide-1lvasgz/'
                          }
                          target="_blank"
                        >
                          {t('Wix users')} <i className="mdi mdi-open-in-new" />{' '}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="divider mt-4 mb-3">
                    <span>{t('Or')}</span>
                  </div>
                  <div className="p-2">
                    {invalid && (
                      <Alert color="danger">
                        {t('Wrong email or password!')}
                      </Alert>
                    )}
                    <form
                      className="form-horizontal"
                      onSubmit={handleSubmit(handleValidSubmit)}
                    >
                      {props.error && typeof props.error === 'string' ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <FormGroup>
                        <Label>{t('Email')}</Label>
                        <input
                          {...register('email', {
                            required: true,
                            pattern: /^.+@.+$/
                          })}
                          className={classnames({
                            'form-control': true,
                            'is-invalid': !!showErrors('email')
                          })}
                          placeholder={t('Enter email')}
                          type="email"
                        />
                        <FormFeedback>{showErrors('email')}</FormFeedback>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <Label>{t('Password')}</Label>
                        <input
                          {...register('password', { required: true })}
                          className={classnames({
                            'form-control': true,
                            'is-invalid': !!showErrors('password')
                          })}
                          type="password"
                          required
                          placeholder={t('Enter password')}
                        />
                        <FormFeedback>{showErrors('password')}</FormFeedback>
                      </FormGroup>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          {t('Remember me')}
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary col-12 waves-effect waves-light"
                          type="submit"
                        >
                          {t('Log In')} <i className="mdi mdi-login" />
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link
                          href="/forgot-password"
                          className="text-muted"
                          legacyBehavior
                        >
                          <a>
                            <i className="mdi mdi-lock me-1" />
                            {t('Forgot your password?')}
                          </a>
                        </Link>
                      </div>
                    </form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p>
                  {t("Don't have an account ?")}{' '}
                  <Link
                    href="/register"
                    className="fw-medium text-primary"
                    legacyBehavior
                  >
                    <a>
                      <span className="fw-medium text-primary">
                        {' '}
                        {t('Signup now')}
                      </span>
                    </a>
                  </Link>
                </p>
                <p>
                  © {new Date().getFullYear()} Promolayer{' '}
                  <i className="mdi mdi-heart text-danger" /> by Peakdigital
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <style jsx>
        {`
          :global(body) {
            min-width: unset;
          }
          .divider {
            display: block;
            height: 20px;
            width: 100%;
            text-align: center;
            position: relative;
          }

          .divider span {
            padding: 0 10px;
            background: #fff;
            z-index: 2;
            position: absolute;
            display: inline-block;
            line-height: 20px;
            left: 50%;
            transform: translateX(-50%);
          }

          .divider:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            background: rgba(206, 212, 218, 0.5);
            z-index: 1;
          }
        `}
      </style>
    </>
  )
}

export default Login
